import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [query, setQuery] = useState(['']);
  const [shipmentDetail, setShipmentDetail] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [reasonDetail, setReasonDetail] = useState(['']);
  const [staffName, setStaffName] = useState(['']);
  const [commentDetail, setCommentDetail] = useState(['']);
  const [statusMessage, setStatusMessage] = useState(['']);
  let custName,
    custPostCode,
    shippingNumber,
    shippingPartner = '';

  useEffect(() => {
    if (query.length >= 6) {
      setStatusMessage('');
      fetchData(query);
    }
  }, [query]);

  const fetchData = async event => {
    setShowDetail(true);
    await axios.get(`https://ab-test.racetrackpitstop.co.uk/shipping/orderById/${event}`).then(response => {
      setShipmentDetail(response.data);
    });
  };
  const submitForm = async e => {
    e.preventDefault();
    await axios
      .post(`https://ab-test.racetrackpitstop.co.uk/shipping/orderById`, {
        orderId: query,
        reason: reasonDetail,
        staff: staffName,
        comments: commentDetail
      })
      .then(response => {
        if (response.status === 200) {
          console.log(response);
          setStatusMessage(response.data);
          setQuery('');
          setCommentDetail('');
          setStaffName('');
          setShipmentDetail([]);
          setShowDetail(false);
        } else {
          setStatusMessage('Error on submission');
        }
      })
      .catch(error => {
        console.log(error);
        setStatusMessage('Error on submission');
      });
  };
  if (typeof shipmentDetail.CustomerInfo != 'undefined') {
    custName = shipmentDetail.CustomerInfo.ChannelBuyerName;
    custPostCode = shipmentDetail.CustomerInfo.Address.PostCode;
    shippingPartner = shipmentDetail.ShippingInfo.Vendor;
    shippingNumber = shipmentDetail.ShippingInfo.TrackingNumber;
  }
  return (
    <div className="container">
      <div className="item-1" />
      <div className="item-2">
        <h1>Shipment Damage</h1>
        <form onSubmit={submitForm}>
          <ul className="flex-outer">
            <li>
              <label htmlFor="linn-id">Linnworks ID</label>
              <input
                type="number"
                id="linn-id"
                placeholder="Enter Linnworks ID here"
                value={query}
                onChange={event => setQuery(event.target.value)}
              />
            </li>
            <li>
              <label htmlFor="reason">Select Reason</label>
              <select
                name="reason"
                onChange={e => {
                  setReasonDetail(e.target.value);
                }}
              >
                <option disabled selected>
                  -- select an option --
                </option>
                <option value="damaged-transit">Damaged in transit</option>
                <option value="lost-transit">Lost in transit</option>
                <option value="not-received">Didn't receive</option>
                <option value="return-to-sender">Returned to sender</option>
              </select>
            </li>
            <li>
              <label htmlFor="last-name">Staff Name</label>
              <input
                type="text"
                id="last-name"
                placeholder="Enter your name here"
                value={staffName}
                onChange={e => {
                  setStaffName(e.target.value);
                }}
              />
            </li>
            <li>
              <label htmlFor="comment">Comments</label>
              <input
                type="text"
                id="comment"
                placeholder="Enter your comments here"
                value={commentDetail}
                onChange={e => {
                  setCommentDetail(e.target.value);
                }}
              />
            </li>
            <li>
              <div className="statusMessage">{statusMessage}</div>
              <button type="submit">Submit</button>
            </li>
          </ul>
        </form>
      </div>
      <div className="item-3" style={{ display: showDetail ? 'block' : 'none' }}>
        <h2>Shipment Detail</h2>
        <ul className="flex-outer">
          <li>
            <label htmlFor="cust-name">Customer Name</label>
            <input type="text" id="cust-name" placeholder="" value={custName} readOnly />
          </li>
          <li>
            <label htmlFor="cust-postcode">Postcode</label>
            <input type="text" id="cust-postcode" placeholder="" value={custPostCode} readOnly />
          </li>
          <li>
            <label htmlFor="cust-name">Shipping Partner</label>
            <input type="text" id="cust-name" placeholder="" value={shippingPartner} readOnly />
          </li>
          <li>
            <label htmlFor="cust-postcode">Tracking Number</label>
            <input type="text" id="cust-postcode" placeholder="" value={shippingNumber} readOnly />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default App;
